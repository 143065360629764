import React from "react"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutServe"
import BgImage from "../images/Serve_Big.jpeg"
import Phoenix from "../images/phoenix_campus_squre.jpeg"
import Surprise from "../images/Surprise_campus_square.jpeg"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

function CampusLocation({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function IndexPage() {
  {
    return (
      <Layout
        ImgSrc={BgImage}
        MainText="Serve at Cross Church"
        SecondText="Be a part of making Jesus Known"
      >
        <SEO title="Serve at Cross Church" />
        <PageHeaders
          css={css`
            display: flex;
          `}
          id="join"
        >
          Choose a Campus
        </PageHeaders>
        <HeaderText></HeaderText>
        <div
          css={css`
            display: flex;
            @media (max-width: 940px) {
              flex-direction: column;
            }
          `}
        >
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(2, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={10}
          >
            <CampusLocation
              imageUrl={Surprise}
              imageAlt="Serve at Surprise Campus"
              sermonTitle="Cross Church Surprise"
              sermonLink="/serve-surprise#join"
            />
            <CampusLocation
              imageUrl={Phoenix}
              imageAlt="Serve at Phoenix Campus"
              sermonTitle="Cross Church Phoenix"
              sermonLink="/serve-phoenix#join"
            />
          </Grid>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
